import React from 'react';
import Layout from 'components/Layout/Layout';
import SEO from 'components/Layout/Seo';
import FounderStory from 'components/About/FounderStory';

const FounderStoryPage = () => (
  <Layout>
    <SEO title="Founder Story" />
    <FounderStory />
  </Layout>
)

export default FounderStoryPage