import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"

const Wrapper = styled(Row)`
  max-width: 750px;
  padding: 30px 0 0 0;
`
const HeadingTwo = styled.h2`
  ${props => props.theme.main.fonts.headingTwo};
  text-align: center;
  padding-bottom: 30px;

  @media only screen and (max-width: 767px) {
    font-size: 26px;
  }
`
const ParagraphTwo = styled.p`
  ${props => props.theme.main.fonts.paragraphTwo};
`

export default () => {
  return (
    <Container fluid>
      <Wrapper>
        <Col>
          <HeadingTwo>Nick's Story</HeadingTwo>

          <ParagraphTwo>
            From the drugstore shelf to your social media feeds, thousands of
            products can promise to give you an ideal body. There are diets and
            supplements to help you get bigger, smaller, leaner, bulkier or
            somehow “better.” But nothing creates true physical and emotional
            freedom like changing how you relate to food. If this sounds too
            simple to be effective, I understand. I’ve been there.
          </ParagraphTwo>

          <ParagraphTwo>
            When I was a kid, running around and playing with friends was my
            only goal. Food was simply replenishment. When my mom called me in
            for lunch or dinner, I would inhale whatever was in front of me. I
            never thought about nutrients, portion sizes, calorie intake, or how
            different foods affected me. My priority was getting back outside,
            as fast as possible.
          </ParagraphTwo>

          <ParagraphTwo>
            My childhood relationship to food was playful, because each meal
            gave me the energy I needed to have fun. I never second-guessed or
            planned what I would eat. My insatiable appetite for sports and the
            outdoors enabled me to maintain this free-thinking attitude well
            into my teen years.
          </ParagraphTwo>

          <ParagraphTwo>
            My home life, however wasn’t quite as healthy. My stepdad was a
            mean-spirited alcoholic who yelled, hurled insults at my mom, and
            left for weeks at a time without notice. These experiences were
            unsettling, uncomfortable, and traumatic. Not until I was older did
            I realize that those days outside with my friends were a critical
            escape.
          </ParagraphTwo>

          <ParagraphTwo>
            When I moved out and started college, new thoughts about how I ate,
            who I was, and who I was supposed to be started to change my
            behavior. Instead of eating to nourish the next six hours of
            exercise and exploration, I ate to fit in. Every meal felt
            stressful, as I tried to mold myself into the person I thought
            people expected me to be.
          </ParagraphTwo>

          <ParagraphTwo>
            By my late 20s, I realized how my early childhood experiences had
            shaped me, and explained why I often felt inadequate – like I wasn’t
            worth sticking around for. I lost myself, and food became a way to
            deal with the anxiety and depression that had slowly crept into my
            life.
          </ParagraphTwo>

          <ParagraphTwo>
            A heaping plate of food enabled me to drown in the TV shows and
            movies I watched on repeat. I ate unconsciously, as a distraction.
            Anxiety hit like a ton of bricks when I turned 25, because I had
            relentlessly ignored who I was and what I really wanted. In
            retrospect, I can see that my body and mind were trying to tell me,
            “you need to get on the right path, because what you’re doing now?
            This ain’t it.”
          </ParagraphTwo>

          <ParagraphTwo>
            Over the course of a single year, I had transformed from a
            functioning human being to a hermit cocooned on the couch or in my
            bed. But, I still didn’t know what was causing these deeply
            unsettling feelings of distress and discomfort. I had panic attacks
            that landed me in the emergency room. I tried counselling. I also
            tried anxiety medications, which didn’t help me at all. Eventually,
            I gained 50 pounds – and I still wasn’t any healthier, mentally or
            physically.
          </ParagraphTwo>

          <ParagraphTwo>
            One day, a friend asked me to join him for a run. Alarm bells went
            off as I stammered out a series of excuses as to why I couldn’t go.
            Eventually, I conceded, despite my overwhelming fear (anxiety works
            in strange ways). We ran about three kilometres. I huffed and puffed
            and wanted to quit the entire time.
          </ParagraphTwo>

          <ParagraphTwo>
            While I’d love to tell you that I had a major breakthrough during
            that run, it didn’t quite happen that way. But, a small window did
            open. I realized that I had the opportunity to feel like a kid
            again. It was within my power. I could move and eat simply for the
            joy of it. So, that’s what I started to do.
          </ParagraphTwo>

          <ParagraphTwo>
            I didn’t diet or exercise for the “gains.” Nor did I obsess about
            portion sizes, macronutrients, or burning off every calorie I
            consumed. I was determined to feel the same freedom and openness I
            had experienced earlier in my life. Some days I stumbled, but
            eventually, I rediscovered that playful relationship with food – and
            I wanted to share what I’d learned.
          </ParagraphTwo>

          <ParagraphTwo>
            Once I started Savor, friends, family members, and even total
            strangers would tell me about their pursuit of the “perfect body.” I
            heard emotional stories about success, failure, and in a few cases,
            the significant damage they had inflicted on their bodies.
          </ParagraphTwo>

          <ParagraphTwo>
            We know that nearly 99% of all people who start a diet will fail,
            meaning they’ll regain the weight they lose – and then some. Yet,
            almost all of us have been told that our bodies aren’t “good enough”
            in some way. Whether it’s from photoshop-enhanced abs on a magazine
            cover to an offhand comment on the playground, we internalize
            negative messages from a young age, and they cause so much
            unnecessary suffering.
          </ParagraphTwo>

          <ParagraphTwo>
            Intuitive eating allows us to make food choices without guilt, fear,
            or other negative emotions. This approach respects hunger and
            fullness and promotes the pleasure of eating without judgment. Once
            I began practicing mindfulness and noticing what drives me to
            restrict or binge eat, all the cultural and social baggage
            eventually fell away. It didn’t happen overnight, but I learned how
            to listen to my body again – and you can, too.
          </ParagraphTwo>

          <ParagraphTwo>
            You have the power to change how you look at food. You can find
            freedom, joy, and peace with what’s on your plate. At Savor, our
            mission is to help you develop a healthier relationship with food,
            one meal at a time.
          </ParagraphTwo>

          <ParagraphTwo>Sincerely,</ParagraphTwo>

          <div class="signature-wrapper"></div>
        </Col>
      </Wrapper>
    </Container>
  )
}
